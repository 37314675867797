import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import ProjectListing from "../components/ProjectListing/ProjectListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./listing.css";
import Content from "../components/Content/Content";
import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";

const img =
  "https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg";

class ProjectsListing extends React.Component {
  // renderPaging() {
  //   const { currentPageNum, pageCount } = this.props.pageContext;
  //   const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
  //   const nextPage = `/${currentPageNum + 1}/`;
  //   const isFirstPage = currentPageNum === 1;
  //   const isLastPage = currentPageNum === pageCount;

  //   return (
  //     <div className="paging-container">
  //       {!isFirstPage && <Link to={prevPage}>Previous</Link>}
  //       {[...Array(pageCount)].map((_val, index) => {
  //         const pageNum = index + 1;
  //         return (
  //           <Link key={`listing-page-${pageNum}`} to={pageNum === 1 ? "/" : `/${pageNum}/`}>
  //             {pageNum}
  //           </Link>
  //         );
  //       })}
  //       {!isLastPage && <Link to={nextPage}>Next</Link>}
  //     </div>
  //   );
  // }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location}>
        <div className="underNavbar">
          <FullWidthImage2 title="Projects" image={img} />
          <Content className="page-content">
            <div className="posts-container">
              <Helmet htmlAttributes={{ lang: "en" }} title={`Projects | ${config.siteTitle}`} />
              <SEO />
              <ProjectListing postEdges={postEdges} />
            </div>
            {/* {this.renderPaging()} TODO: Add paging and style this. */}
          </Content>
        </div>
      </Layout>
    );
  }
}

export default ProjectsListing;

/* eslint no-undef: "off" */
export const ProjectListingQuery = graphql`
  query ProjectListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___design], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(projects)/.*.md$/" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 280)
          timeToRead
          frontmatter {
            title
            # tags
            thumbnail
            date
            location
            category
          }
        }
      }
    }
  }
`;
